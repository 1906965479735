<template>
  <div>
    <div
      class="modal fade"
      id="modal-form-odometro"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      style="background-color: #00000082"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white" v-if="turno">
              <strong>Odómetros Turno:</strong> {{ turno.id }}
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModalOdometro"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" v-if="turno">
            <div class="row">
              <div class="col-md-12">
                <div class="position-relative p-3 m-2 bg-light">
                  <div class="ribbon-wrapper ribbon-lg">
                    <div class="ribbon bg-secondary">Odómetros</div>
                  </div>

                  <div class="col-md-12">
                    <div class="row">
                      <div
                        class="col-sm-6 d-flex align-items-stretch flex-column"
                      >
                        <div class="card bg-white d-flex flex-fill">
                          <div class="card-header text-muted border-bottom-0">
                            Odómetro Inicial
                          </div>
                          <div class="card-body pt-0">
                            <div class="form-row">
                              <div>
                                <input
                                  type="number"
                                  class="form-control form-control-sm"
                                  id="odometro"
                                  placeholder="Ingrese Odometro Inicial"
                                  v-model="form.odometro_ini"
                                  :class="
                                    $v.form.odometro_ini.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                  @change="validarOdometro(1)"
                                  :disabled="turno.tif_conciliacion"
                                />
                                <div
                                  class="form-group col-md-4"
                                  v-if="
                                    form.link_odometro_ini == null ||
                                      form.link_odometro_ini == ''
                                  "
                                >
                                  <label for="imagen" class="font-italic"
                                    >Foto Odómetro Inicial</label
                                  >

                                  <input
                                    type="file"
                                    class="form-control-file"
                                    accept="image/x-png, image/gif, image/jpeg"
                                    @change="obtenerImagen1"
                                  />
                                </div>
                                <hr />
                                <div class="form-group col-md-4">
                                  <figure v-if="miniLogo1">
                                    <div class="button-container">
                                      <img
                                        :src="miniLogo1"
                                        alt="Logo"
                                        width="236"
                                        height="125"
                                        class="rounded mx-auto d-block"
                                      />
                                      <a
                                        @click="destroyImage()"
                                        id="BotonEliminar"
                                        v-if="
                                          form.link_odometro_ini &&
                                            $store.getters.can(
                                              'tif.conciliaciones.deleteOdometro'
                                            ) &&
                                            !turno.tif_conciliacion
                                        "
                                        href="#"
                                      >
                                        Eliminar
                                        <i class="fas fa-window-close"></i>
                                      </a>
                                    </div>
                                  </figure>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-md-6 d-flex align-items-stretch flex-column"
                      >
                        <div class="card bg-white d-flex flex-fill">
                          <div class="card-header text-muted border-bottom-0">
                            Odómetro Final
                          </div>
                          <div class="card-body pt-0">
                            <div class="row">
                              <div>
                                <input
                                  type="number"
                                  class="form-control form-control-sm"
                                  id="odometro"
                                  placeholder="Ingrese Odometro Final"
                                  v-model="form.odometro_fin"
                                  :class="
                                    $v.form.odometro_fin.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                  @change="validarOdometro(2)"
                                />
                                <div
                                  class="form-group col-md-4"
                                  v-if="
                                    form.link_odometro_fin == null ||
                                      form.link_odometro_fin == ''
                                  "
                                >
                                  <label for="imagen" class="font-italic"
                                    >Foto Odómetro Final</label
                                  >
                                  <input
                                    type="file"
                                    class="form-control-file"
                                    accept="image/x-png, image/gif, image/jpeg"
                                    @change="obtenerImagen2"
                                  />
                                </div>
                                <hr />
                                <div class="form-group col-md-4">
                                  <figure class="form-group" v-if="miniLogo2">
                                    <div class="button-container">
                                      <img
                                        :src="miniLogo2"
                                        alt="Logo"
                                        width="236"
                                        height="125"
                                        class="rounded mx-auto d-block"
                                      />
                                      <a
                                        @click="destroyImage2()"
                                        id="BotonEliminar"
                                        v-if="
                                          form.link_odometro_fin &&
                                            $store.getters.can(
                                              'tif.conciliaciones.deleteOdometro'
                                            ) &&
                                            !turno.tif_conciliacion
                                        "
                                        href="#"
                                      >
                                        Eliminar
                                        <i class="fas fa-window-close"></i>
                                      </a>
                                    </div>
                                  </figure>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <button
                  class="btn bg-primary col-md-1"
                  v-show="
                    $store.getters.can('tif.conciliaciones.subirOdometro') &&
                      (miniLogo1 || miniLogo2) &&
                      !$v.form.$invalid &&
                      !turno.tif_conciliacion
                  "
                  @click="saveImage()"
                >
                  <i class="fas fa-paper-plane"></i><br />Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</template>
<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
export default {
  name: "TifTurnoOdo",
  components: {},
  data() {
    return {
      cargando: false,
      turno: null,
      miniLogo1: null,
      miniLogo2: null,
      archivoValido: false,
      fileFotografia1: null,
      fileFotografia2: null,
      accion: "",
      metodo: "",
      error: "",
      form: { id: null },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations() {
    return {
      form: {
        odometro_ini: {
          required,
        },
        odometro_fin: {
          required,
        },
      },
    };
  },
  methods: {
    async llenar_modal_odometro(item) {
      this.turno = await item;
      if (item.tif_odometro) {
        let f = {
          odometro_ini: this.turno.tif_odometro.inicial,
          odometro_fin: this.turno.tif_odometro.final,
          link_odometro_ini: this.turno.tif_odometro.link_ini,
          link_odometro_fin: this.turno.tif_odometro.link_fin,
          id: this.turno.tif_odometro.id,
        };
        this.form = f;
        this.miniLogo1 = this.uri_docs + this.turno.tif_odometro.link_ini;
        this.miniLogo2 = this.uri_docs + this.turno.tif_odometro.link_fin;
      } else {
        this.limpiarModal();
      }
    },

    limpiarModal() {
      this.form.odometro_ini = null;
      this.form.odometro_fin = null;
      this.form.link_odometro_ini = null;
      this.form.link_odometro_fin = null;
      this.miniLogo1 = null;
      this.miniLogo2 = null;
      this.archivoValido = false;
      this.fileFotografia1 = null;
      this.fileFotografia2 = null;
      this.form = {};
      this.$parent.getIndex(this.$parent.page);
    },

    obtenerImagen1(e) {
      this.miniLogo1 = null;
      this.archivoValido = false;
      if (e.target.files[0]) {
        this.cargando = true;
        const file = e.target.files[0];
        if (this.validaArchivo(file)) {
          this.archivoValido = true;
          this.fileFotografia1 = file;
          this.cargarImagen1(file);
        }
      }
    },

    obtenerImagen2(e) {
      this.miniLogo2 = null;
      this.archivoValido = false;
      if (e.target.files[0]) {
        this.cargando = true;
        const file = e.target.files[0];
        if (this.validaArchivo(file)) {
          this.archivoValido = true;
          this.fileFotografia2 = file;
          this.cargarImagen2(file);
        }
      }
    },

    validaArchivo(file) {
      let flag = true;
      if (file.size > 1024000) {
        flag = false;
        this.fileFotografia1 = null;
        this.cargando = false;
        this.$swal({
          icon: "error",
          title: `El Archivo es muy pesado para subirlo`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return flag;
    },

    validarOdometro(odo) {
      if (
        parseInt(this.form.odometro_ini) <
          parseInt(this.turno.vehiculo.odometro) &&
        odo == 1
      ) {
        this.$swal({
          text:
            "El valor del Odometro no puede ser menor al Odometro Actual del vehiculo. Odometro Vehiculo " +
            this.turno.vehiculo.odometro,
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
        this.form.odometro_ini = null;
      }

      if (parseInt(this.form.odometro_fin) < parseInt(this.form.odometro_ini)) {
        this.$swal({
          text:
            "El valor del Odómetro Inicial no puede ser mayor al Odómetro Final..! ",
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
        this.form.odometro_ini = null;
        this.form.odometro_fin = null;
      }
    },

    cargarImagen1(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.miniLogo1 = e.target.result;
      };
      reader.readAsDataURL(file);
      this.cargando = false;
    },

    cargarImagen2(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.miniLogo2 = e.target.result;
      };
      reader.readAsDataURL(file);
      this.cargando = false;
    },

    saveImage() {
      this.cargando = true;
      let formData = new FormData();
      formData.append("id", this.form.id);
      formData.append("tif_turno_id", this.turno.id);
      formData.append("vehiculo_id", this.turno.vehiculo_id);
      formData.append("inicial", this.form.odometro_ini);
      formData.append("link_ini", this.fileFotografia1);
      formData.append("final", this.form.odometro_fin);
      formData.append("link_fin", this.fileFotografia2);
      axios
        .post("api/tif/conciliaciones/odometro", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$refs.closeModalOdometro.click();
          this.$parent.getIndex(this.$parent.page);
          this.cargando = false;
          this.$swal({
            icon: "success",
            title: "Los datos se guardaron exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.archivoValido = false;
          this.form.link_odometro_ini = response.data.link_ini;
          this.form.link_odometro_fin = response.data.link_fin;
          this.form.id = response.data.id;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    destroyImage() {
      this.$swal({
        title: "Esta seguro de eliminar esta Imagen?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete(
              "api/tif/conciliaciones/odometroIni/" + this.turno.tif_odometro.id
            )
            .then((response) => {
              this.$parent.getIndex(this.$parent.page);
              this.$swal({
                icon: "success",
                title: "La imagen se elimino correctamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch(function(error) {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error" + error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
          this.miniLogo1 = null;
          this.form.link_odometro_ini = null;
        }
      });
    },

    destroyImage2() {
      this.$swal({
        title: "Esta seguro de eliminar esta Imagen?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete(
              "api/tif/conciliaciones/odometroFin/" + this.turno.tif_odometro.id
            )
            .then((response) => {
              this.$parent.getIndex(this.$parent.page);
              this.$swal({
                icon: "success",
                title: "La imagen se elimino correctamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch(function(error) {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error" + error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
          this.miniLogo2 = null;
          this.form.link_odometro_fin = null;
        }
      });
    },

    init() {
      this.accion = this.$route.params.accion;
      if (
        this.$route.params.accion == "Inicial" &&
        this.$route.params.accion == "Final"
      ) {
        this.form = this.$route.params.data_turno;
        this.miniLogo1 = this.uri_docs + this.form.link_odometro_ini;
        this.form = this.$route.params.data_turno;
        this.miniLogo2 = this.uri_docs + this.form.link_odometro_fin;
        this.form.id = this.$route.params.data_turno.id;
        this.metodo = "PUT";
      }
    },
  },

  async mounted() {
    this.cargando = true;
    await this.init();
    this.cargando = false;
  },
};
</script>
<style>
.button-container {
  display: inline-block;
  position: relative;
}

.button-container a {
  bottom: 3em;
  right: 4em;
  color: white;
  text-transform: uppercase;
  padding: 1em 1.8em;
  opacity: 0.3;
  color: #fff !important;
  background: #ff0000;
  position: absolute;
  outline: 2px solid;
  outline-color: rgba(248, 8, 8, 0.89);
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.button-container a:hover {
  box-shadow: inset 0 0 20px rgba(248, 8, 8, 0.89),
    0 0 20px rgba(248, 8, 8, 0.89);
  outline-color: rgba(49, 138, 172, 0);
  outline-offset: 80px;
  text-shadow: 3px 3px 3px #fff;
}
</style>
