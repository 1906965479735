var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"modal fade",staticStyle:{"background-color":"#00000082"},attrs:{"id":"modal-form-odometro","tabindex":"-1","role":"dialog","aria-hidden":"true","data-backdrop":"static","data-keyboard":"false"}},[_c('div',{staticClass:"modal-dialog modal-xl",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[(_vm.turno)?_c('h4',{staticClass:"modal-title text-white"},[_c('strong',[_vm._v("Odómetros Turno:")]),_vm._v(" "+_vm._s(_vm.turno.id)+" ")]):_vm._e(),_c('button',{ref:"closeModalOdometro",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),(_vm.turno)?_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"position-relative p-3 m-2 bg-light"},[_vm._m(0),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6 d-flex align-items-stretch flex-column"},[_c('div',{staticClass:"card bg-white d-flex flex-fill"},[_c('div',{staticClass:"card-header text-muted border-bottom-0"},[_vm._v(" Odómetro Inicial ")]),_c('div',{staticClass:"card-body pt-0"},[_c('div',{staticClass:"form-row"},[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.odometro_ini),expression:"form.odometro_ini"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.odometro_ini.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid',attrs:{"type":"number","id":"odometro","placeholder":"Ingrese Odometro Inicial","disabled":_vm.turno.tif_conciliacion},domProps:{"value":(_vm.form.odometro_ini)},on:{"change":function($event){return _vm.validarOdometro(1)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "odometro_ini", $event.target.value)}}}),(
                                  _vm.form.link_odometro_ini == null ||
                                    _vm.form.link_odometro_ini == ''
                                )?_c('div',{staticClass:"form-group col-md-4"},[_c('label',{staticClass:"font-italic",attrs:{"for":"imagen"}},[_vm._v("Foto Odómetro Inicial")]),_c('input',{staticClass:"form-control-file",attrs:{"type":"file","accept":"image/x-png, image/gif, image/jpeg"},on:{"change":_vm.obtenerImagen1}})]):_vm._e(),_c('hr'),_c('div',{staticClass:"form-group col-md-4"},[(_vm.miniLogo1)?_c('figure',[_c('div',{staticClass:"button-container"},[_c('img',{staticClass:"rounded mx-auto d-block",attrs:{"src":_vm.miniLogo1,"alt":"Logo","width":"236","height":"125"}}),(
                                        _vm.form.link_odometro_ini &&
                                          _vm.$store.getters.can(
                                            'tif.conciliaciones.deleteOdometro'
                                          ) &&
                                          !_vm.turno.tif_conciliacion
                                      )?_c('a',{attrs:{"id":"BotonEliminar","href":"#"},on:{"click":function($event){return _vm.destroyImage()}}},[_vm._v(" Eliminar "),_c('i',{staticClass:"fas fa-window-close"})]):_vm._e()])]):_vm._e()])])])])])]),_c('div',{staticClass:"col-md-6 d-flex align-items-stretch flex-column"},[_c('div',{staticClass:"card bg-white d-flex flex-fill"},[_c('div',{staticClass:"card-header text-muted border-bottom-0"},[_vm._v(" Odómetro Final ")]),_c('div',{staticClass:"card-body pt-0"},[_c('div',{staticClass:"row"},[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.odometro_fin),expression:"form.odometro_fin"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.odometro_fin.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid',attrs:{"type":"number","id":"odometro","placeholder":"Ingrese Odometro Final"},domProps:{"value":(_vm.form.odometro_fin)},on:{"change":function($event){return _vm.validarOdometro(2)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "odometro_fin", $event.target.value)}}}),(
                                  _vm.form.link_odometro_fin == null ||
                                    _vm.form.link_odometro_fin == ''
                                )?_c('div',{staticClass:"form-group col-md-4"},[_c('label',{staticClass:"font-italic",attrs:{"for":"imagen"}},[_vm._v("Foto Odómetro Final")]),_c('input',{staticClass:"form-control-file",attrs:{"type":"file","accept":"image/x-png, image/gif, image/jpeg"},on:{"change":_vm.obtenerImagen2}})]):_vm._e(),_c('hr'),_c('div',{staticClass:"form-group col-md-4"},[(_vm.miniLogo2)?_c('figure',{staticClass:"form-group"},[_c('div',{staticClass:"button-container"},[_c('img',{staticClass:"rounded mx-auto d-block",attrs:{"src":_vm.miniLogo2,"alt":"Logo","width":"236","height":"125"}}),(
                                        _vm.form.link_odometro_fin &&
                                          _vm.$store.getters.can(
                                            'tif.conciliaciones.deleteOdometro'
                                          ) &&
                                          !_vm.turno.tif_conciliacion
                                      )?_c('a',{attrs:{"id":"BotonEliminar","href":"#"},on:{"click":function($event){return _vm.destroyImage2()}}},[_vm._v(" Eliminar "),_c('i',{staticClass:"fas fa-window-close"})]):_vm._e()])]):_vm._e()])])])])])])])])])])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"row"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.$store.getters.can('tif.conciliaciones.subirOdometro') &&
                    (_vm.miniLogo1 || _vm.miniLogo2) &&
                    !_vm.$v.form.$invalid &&
                    !_vm.turno.tif_conciliacion
                ),expression:"\n                  $store.getters.can('tif.conciliaciones.subirOdometro') &&\n                    (miniLogo1 || miniLogo2) &&\n                    !$v.form.$invalid &&\n                    !turno.tif_conciliacion\n                "}],staticClass:"btn bg-primary col-md-1",on:{"click":function($event){return _vm.saveImage()}}},[_c('i',{staticClass:"fas fa-paper-plane"}),_c('br'),_vm._v("Guardar ")])])])]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ribbon-wrapper ribbon-lg"},[_c('div',{staticClass:"ribbon bg-secondary"},[_vm._v("Odómetros")])])
}]

export { render, staticRenderFns }